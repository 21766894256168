import { default as abonnement_45geactiveerdC6VJm7XXOKMeta } from "/home/cleavr/versvandevelde.nl/releases/20250127081557446/pages/abonnement-geactiveerd.vue?macro=true";
import { default as abonnement_45geannuleerdnFcwyhxPkQMeta } from "/home/cleavr/versvandevelde.nl/releases/20250127081557446/pages/abonnement-geannuleerd.vue?macro=true";
import { default as abonnement_45samenstellenny3zo5Q47dMeta } from "/home/cleavr/versvandevelde.nl/releases/20250127081557446/pages/abonnement-samenstellen.vue?macro=true";
import { default as abonnement7agSZp9TR4Meta } from "/home/cleavr/versvandevelde.nl/releases/20250127081557446/pages/abonnement.vue?macro=true";
import { default as abonnement68qkA69D7QMeta } from "/home/cleavr/versvandevelde.nl/releases/20250127081557446/pages/account/abonnement.vue?macro=true";
import { default as adresTGLnsNMGWbMeta } from "/home/cleavr/versvandevelde.nl/releases/20250127081557446/pages/account/adres.vue?macro=true";
import { default as gegevenstsYo0gA2zBMeta } from "/home/cleavr/versvandevelde.nl/releases/20250127081557446/pages/account/gegevens.vue?macro=true";
import { default as indexzOiupFYzDbMeta } from "/home/cleavr/versvandevelde.nl/releases/20250127081557446/pages/account/index.vue?macro=true";
import { default as resetwachtwoordP6jaineQl7Meta } from "/home/cleavr/versvandevelde.nl/releases/20250127081557446/pages/account/resetwachtwoord.vue?macro=true";
import { default as wachtwoord_45wijzigenjBQIbRz5RyMeta } from "/home/cleavr/versvandevelde.nl/releases/20250127081557446/pages/account/wachtwoord-wijzigen.vue?macro=true";
import { default as activeren2DdpjAyk76Meta } from "/home/cleavr/versvandevelde.nl/releases/20250127081557446/pages/activeren.vue?macro=true";
import { default as afrekenenvwMTl4CuEWMeta } from "/home/cleavr/versvandevelde.nl/releases/20250127081557446/pages/afrekenen.vue?macro=true";
import { default as algemenevoorwaardenXsXJfjxG1dMeta } from "/home/cleavr/versvandevelde.nl/releases/20250127081557446/pages/algemenevoorwaarden.vue?macro=true";
import { default as contactgSxNe8FIGrMeta } from "/home/cleavr/versvandevelde.nl/releases/20250127081557446/pages/contact.vue?macro=true";
import { default as indexSCFhdOViE6Meta } from "/home/cleavr/versvandevelde.nl/releases/20250127081557446/pages/index.vue?macro=true";
import { default as inloggenJGQSF7MOUcMeta } from "/home/cleavr/versvandevelde.nl/releases/20250127081557446/pages/inloggen.vue?macro=true";
import { default as overonsKS0pgnFt1HMeta } from "/home/cleavr/versvandevelde.nl/releases/20250127081557446/pages/overons.vue?macro=true";
import { default as registreren7ltAIbFUFxMeta } from "/home/cleavr/versvandevelde.nl/releases/20250127081557446/pages/registreren.vue?macro=true";
export default [
  {
    name: "abonnement-geactiveerd",
    path: "/abonnement-geactiveerd",
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20250127081557446/pages/abonnement-geactiveerd.vue").then(m => m.default || m)
  },
  {
    name: "abonnement-geannuleerd",
    path: "/abonnement-geannuleerd",
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20250127081557446/pages/abonnement-geannuleerd.vue").then(m => m.default || m)
  },
  {
    name: "abonnement-samenstellen",
    path: "/abonnement-samenstellen",
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20250127081557446/pages/abonnement-samenstellen.vue").then(m => m.default || m)
  },
  {
    name: "abonnement",
    path: "/abonnement",
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20250127081557446/pages/abonnement.vue").then(m => m.default || m)
  },
  {
    name: "account-abonnement",
    path: "/account/abonnement",
    meta: abonnement68qkA69D7QMeta || {},
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20250127081557446/pages/account/abonnement.vue").then(m => m.default || m)
  },
  {
    name: "account-adres",
    path: "/account/adres",
    meta: adresTGLnsNMGWbMeta || {},
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20250127081557446/pages/account/adres.vue").then(m => m.default || m)
  },
  {
    name: "account-gegevens",
    path: "/account/gegevens",
    meta: gegevenstsYo0gA2zBMeta || {},
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20250127081557446/pages/account/gegevens.vue").then(m => m.default || m)
  },
  {
    name: "account",
    path: "/account",
    meta: indexzOiupFYzDbMeta || {},
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20250127081557446/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account-resetwachtwoord",
    path: "/account/resetwachtwoord",
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20250127081557446/pages/account/resetwachtwoord.vue").then(m => m.default || m)
  },
  {
    name: "account-wachtwoord-wijzigen",
    path: "/account/wachtwoord-wijzigen",
    meta: wachtwoord_45wijzigenjBQIbRz5RyMeta || {},
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20250127081557446/pages/account/wachtwoord-wijzigen.vue").then(m => m.default || m)
  },
  {
    name: "activeren",
    path: "/activeren",
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20250127081557446/pages/activeren.vue").then(m => m.default || m)
  },
  {
    name: "afrekenen",
    path: "/afrekenen",
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20250127081557446/pages/afrekenen.vue").then(m => m.default || m)
  },
  {
    name: "algemenevoorwaarden",
    path: "/algemenevoorwaarden",
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20250127081557446/pages/algemenevoorwaarden.vue").then(m => m.default || m)
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20250127081557446/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20250127081557446/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "inloggen",
    path: "/inloggen",
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20250127081557446/pages/inloggen.vue").then(m => m.default || m)
  },
  {
    name: "overons",
    path: "/overons",
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20250127081557446/pages/overons.vue").then(m => m.default || m)
  },
  {
    name: "registreren",
    path: "/registreren",
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20250127081557446/pages/registreren.vue").then(m => m.default || m)
  }
]